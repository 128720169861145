<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Nhà cung cấp</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div>
        <div class="font-weight-bold mb-2">Loại nhà cung cấp</div>
        <v-autocomplete
          v-model="selectedSupplierCategories"
          class="tp-filter-autocomplete text-body-1"
          flat
          dense
          deletable-chips
          hide-details
          hide-selected
          :items="allSupplierCategories"
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn loại"
          solo
          small-chips
          single-line
        ></v-autocomplete>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Tổng tiền mua</div>
        <tp-input-price-filter
          :items="buyTotalValueList"
          :value="buyTotalFilter"
          @change="updateBuyTotalFilter($event)"
        ></tp-input-price-filter>
      </div>
      <div class="mt-6">
        <div class="font-weight-bold mb-2">Tổng tiền trả</div>
        <tp-input-price-filter
          :items="returnTotalValueList"
          :value="returnTotalFilter"
          @change="updateReturnTotalFilter($event)"
        ></tp-input-price-filter>
      </div>
      <div class="mt-6">
        <div class="font-weight-bold mb-2">Công nợ</div>
        <tp-input-price-filter
          :items="debtValueList"
          :value="debtFilter"
          @change="updateDebtFilter($event)"
        ></tp-input-price-filter>
      </div>
      <div class="mt-6 mb-2">
        <div class="font-weight-bold mb-2">Giao dịch cuối</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    buyTotalFilter: {
      type: Object
    },
    dateFilter: {
      type: Object
    },
    debtFilter: {
      type: Object
    },
    returnTotalFilter: {
      type: Object
    },
    supplierCategoriesFilter: {
      type: Array
    }
  },
  data() {
    return {
      buyTotalValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        {
          id: 4,
          text: "10 - 30 triệu",
          value: { from: 10000000, to: 30000000 }
        },
        {
          id: 4,
          text: "30 - 50 triệu",
          value: { from: 30000000, to: 50000000 }
        },
        { id: 4, text: "Trên 100 triệu", value: { from: 100000000, to: null } }
      ],
      debtValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        { id: 4, text: "Nhỏ hơn 0", value: { from: null, to: -0.000001 } }
      ],
      returnTotalValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } }
      ]
    };
  },
  computed: {
    allSupplierCategories() {
      return this.$store.getters["SUPPLIER/allSupplierCategories"];
    },
    selectedSupplierCategories: {
      get() {
        return this.supplierCategoriesFilter;
      },
      set(val) {
        this.$emit("updateSupplierCategoriesFilter", val);
      }
    }
  },
  async created() {
    await this.$store.dispatch("SUPPLIER/getAllSupplierCategories");
  },
  methods: {
    updateBuyTotalFilter(val) {
      this.$emit("updateBuyTotalFilter", val);
    },
    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    },
    updateDebtFilter(val) {
      this.$emit("updateDebtFilter", val);
    },
    updateReturnTotalFilter(val) {
      this.$emit("updateReturnTotalFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
