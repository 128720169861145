var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.supplierStatusRequest.value === 'loading-getSuppliers',"loading-text":"Đang tải dữ liệu","items":_vm.suppliers,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name ? item.name : "N/A"))])]}},{key:"item.acronym",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.acronym ? item.acronym : "N/A"))])]}},{key:"item.sup_cates",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-2 mb-1"},_vm._l((item.sup_cates),function(category,index){return _c('v-chip',{key:category.id,staticClass:"font-weight-medium mb-1 px-2",class:{ 'mr-1': index < item.sup_cates.length - 1 },attrs:{"color":_vm.categoryColors[category.id - 1].color,"label":"","small":""}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(item.phone ? item.phone : "N/A")+" ")])]}},{key:"item.tong_mua",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.tong_mua))+" ")]}},{key:"item.tong_tra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.tong_tra))+" ")]}},{key:"item.cong_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.cong_no))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }