<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :buy-total-filter="buyTotalFilter"
        :date-filter="dateFilter"
        :debt-filter="debtFilter"
        :return-total-filter="returnTotalFilter"
        :supplier-categories-filter="supplierCategoriesFilter"
        @updateBuyTotalFilter="updateBuyTotalFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateDebtFilter="updateDebtFilter($event)"
        @updateReturnTotalFilter="updateReturnTotalFilter($event)"
        @updateSupplierCategoriesFilter="updateSupplierCategoriesFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      buyTotalFilter: {
        from: null,
        to: null
      },
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      debtFilter: {
        from: null,
        to: null
      },
      itemPerPage: 50,
      returnTotalFilter: {
        from: null,
        to: null
      },
      searchKey: null,
      supplierCategoriesFilter: []
    };
  },
  watch: {
    "$route.query"(val) {
      this.getSuppliers(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getSuppliers(route.query);
  },
  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "partner-and-member_suppliers",
        query: {
          sup_cate_ids:
            this.supplierCategoriesFilter &&
            this.supplierCategoriesFilter.length > 0
              ? this.supplierCategoriesFilter
              : undefined,
          buy_from:
            this.buyTotalFilter.from !== null
              ? this.buyTotalFilter.from
              : undefined,
          buy_to:
            this.buyTotalFilter.to !== null
              ? this.buyTotalFilter.to
              : undefined,
          return_from:
            this.returnTotalFilter.from !== null
              ? this.returnTotalFilter.from
              : undefined,
          return_to:
            this.returnTotalFilter.to !== null
              ? this.returnTotalFilter.to
              : undefined,
          debt_from:
            this.debtFilter.from !== null ? this.debtFilter.from : undefined,
          debt_to: this.debtFilter.to !== null ? this.debtFilter.to : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getSuppliers(query) {
      if (query.page) {
        // Set buy total filter
        this.buyTotalFilter = {
          from:
            query.buy_from !== undefined ? parseFloat(query.buy_from) : null,
          to: query.buy_to !== undefined ? parseFloat(query.buy_to) : null
        };
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set debt filter
        this.debtFilter = {
          from:
            query.debt_from !== undefined ? parseFloat(query.debt_from) : null,
          to: query.debt_to !== undefined ? parseFloat(query.debt_to) : null
        };
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set return total filter
        this.returnTotalFilter = {
          from:
            query.return_from !== undefined
              ? parseFloat(query.return_from)
              : null,
          to: query.return_to !== undefined ? parseFloat(query.return_to) : null
        };
        // Set filtered supplier categories
        this.supplierCategoriesFilter =
          typeof query.sup_cate_ids === "string"
            ? [parseInt(query.sup_cate_ids)]
            : typeof query.sup_cate_ids === "object"
            ? query.sup_cate_ids.map(item => parseInt(item))
            : [];
        // Set search key
        this.searchKey = query.search || null;

        // Get products
        await this.$store.dispatch("SUPPLIER/getSuppliers", {
          filter: {
            sup_cate_ids: this.supplierCategoriesFilter,
            fromBuy: this.buyTotalFilter.from,
            toBuy: this.buyTotalFilter.to,
            fromReturn: this.returnTotalFilter.from,
            toReturn: this.returnTotalFilter.to,
            fromBalance: this.debtFilter.from,
            toBalance: this.debtFilter.to,
            fromDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            toDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateBuyTotalFilter(val) {
      this.buyTotalFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateDebtFilter(val) {
      this.debtFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateReturnTotalFilter(val) {
      this.returnTotalFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSupplierCategoriesFilter(val) {
      this.supplierCategoriesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
