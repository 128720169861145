<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="supplierStatusRequest.value === 'loading-getSuppliers'"
    loading-text="Đang tải dữ liệu"
    :items="suppliers"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <div>{{ item.name ? item.name : "N/A" }}</div>
    </template>

    <template v-slot:[`item.acronym`]="{ item }">
      <div>{{ item.acronym ? item.acronym : "N/A" }}</div>
    </template>

    <template v-slot:[`item.sup_cates`]="{ item }">
      <div class="mt-2 mb-1">
        <v-chip
          class="font-weight-medium mb-1 px-2"
          :class="{ 'mr-1': index < item.sup_cates.length - 1 }"
          :color="categoryColors[category.id - 1].color"
          v-for="(category, index) in item.sup_cates"
          :key="category.id"
          label
          small
        >
          {{ category.name }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.phone`]="{ item }">
      <div style="width: 120px">
        {{ item.phone ? item.phone : "N/A" }}
      </div>
    </template>

    <template v-slot:[`item.tong_mua`]="{ item }">
      {{ item.tong_mua | formatCurrency }}
    </template>

    <template v-slot:[`item.tong_tra`]="{ item }">
      {{ item.tong_tra | formatCurrency }}
    </template>

    <template v-slot:[`item.cong_no`]="{ item }">
      {{ item.cong_no | formatCurrency }}
    </template>
  </v-data-table>
</template>

<script>
import { LIST_SUPPLIER_CATEGORIES } from "../../../constant";
import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  data() {
    return {
      categoryColors: LIST_SUPPLIER_CATEGORIES,
      headers: [
        {
          text: "Mã NCC",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên NCC",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Tên viết tắt",
          align: "start",
          sortable: false,
          value: "acronym"
        },
        {
          text: "Loại NCC",
          align: "start",
          sortable: false,
          value: "sup_cates"
        },
        {
          text: "SĐT",
          align: "start",
          sortable: false,
          value: "phone"
        },
        {
          text: "Tổng mua",
          align: "center",
          sortable: false,
          value: "tong_mua"
        },
        {
          text: "Tổng trả",
          align: "center",
          sortable: false,
          value: "tong_tra"
        },
        {
          text: "Công nợ",
          align: "center",
          sortable: false,
          value: "cong_no"
        }
      ]
    };
  },
  computed: {
    suppliers() {
      return this.$store.getters["SUPPLIER/suppliers"];
    },
    supplierStatusRequest() {
      return this.$store.getters["SUPPLIER/statusRequest"];
    }
  },
  methods: {
    async viewDetail(item) {
      await this.$store.dispatch("SUPPLIER/getSupplierById", item.id);

      this.$modal.show({ name: "modal-supplier" });

      this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
        id: item.id,
        type: BANK_ACCOUNT_OBJECT_TYPE.SUPPLIER
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
